<template>
  <a-tooltip :placement="'top'">
    <template slot="title">
      {{ text[`sp_${type}`] ? text[`sp_${type}`][language] : "" }}
    </template>
    <div @click="handleClick" class="block__flex block__align-center block__center">
      <StraightLineLogo
        :stroke="active ? activeColor : notActiveColor"
        :style="`cursor: pointer;`"
        v-if="type === 'straightness'"
      />
      <RoundLogo
        v-if="type === 'round'"
        :stroke="active ? activeColor : notActiveColor"
        :style="`cursor: pointer;`"
      />
      <SurfaceLogo
        v-if="type === 'surface'"
        :stroke="active ? activeColor : notActiveColor"
        :style="`cursor: pointer;`"
      />
      <CylinderLogo
        v-if="type === 'cylinder'"
        :stroke="active ? activeColor : notActiveColor"
        :style="`cursor: pointer;`"
      />
      <ProfileLogo
        v-if="type === 'profile'"
        :stroke="active ? activeColor : notActiveColor"
        :style="`cursor: pointer;`"
      />
      <ParallelLogo
        v-if="type === 'parallelism'"
        :stroke="active ? activeColor : notActiveColor"
        :style="`cursor: pointer;`"
      />
      <SymmetryLogo
        v-if="type === 'symmetry'"
        :stroke="active ? activeColor : notActiveColor"
        :style="`cursor: pointer;`"
      />
      <AngleLogo
        v-if="type === 'inclination'"
        :stroke="active ? activeColor : notActiveColor"
        :style="`cursor: pointer;`"
      />
      <Logo90Deg
        v-if="type === 'orthogonal'"
        :stroke="active ? activeColor : notActiveColor"
        :style="`cursor: pointer;`"
      />
      <PositionLogo
        v-if="type === 'position'"
        :stroke="active ? activeColor : notActiveColor"
        :style="`cursor: pointer;`"
      />
      <BeatLogo
        v-if="type === 'beat'"
        :stroke="active ? activeColor : notActiveColor"
        :fill="active ? activeColor : notActiveColor"
        :style="`cursor: pointer;`"
      />
      <AlignmentLogo
        v-if="type === 'alignment'"
        :stroke="active ? activeColor : notActiveColor"
        :style="`cursor: pointer;`"
      />
      <Parallels2Logo
        v-if="type === 'intersection'"
        :stroke="active ? activeColor : notActiveColor"
        :style="`cursor: pointer;`"
      />
    </div>
  </a-tooltip>
</template>

<script>
import text from "@/assets/text/tech.json";
import StraightLineLogo from "@/assets/images/tech/straightline.svg";
import RoundLogo from "@/assets/images/tech/round.svg";
import SurfaceLogo from "@/assets/images/tech/surface.svg";
import ProfileLogo from "@/assets/images/tech/profile.svg";
import ParallelLogo from "@/assets/images/tech/parallel.svg";
import SymmetryLogo from "@/assets/images/tech/symmetry.svg";
import AngleLogo from "@/assets/images/tech/angle.svg";
import Logo90Deg from "@/assets/images/tech/90deg.svg";
import PositionLogo from "@/assets/images/tech/position.svg";
import BeatLogo from "@/assets/images/tech/bienie.svg";
import AlignmentLogo from "@/assets/images/tech/coaxis.svg";
import Parallels2Logo from "@/assets/images/tech/parallel2.svg";
import CylinderLogo from "@/assets/images/tech/cylinder.svg";

export default {
  name: "AccType",
  data() {
    return {
      text,
      activeColor: "#BD3122",
      notActiveColor: "#BABABA",
    };
  },
  props: {
    type: {
      type: String,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    handleClick() {
      this.$emit("click", this.type);
    },
  },
  components: {
    StraightLineLogo,
    RoundLogo,
    SurfaceLogo,
    CylinderLogo,
    ProfileLogo,
    ParallelLogo,
    SymmetryLogo,
    AngleLogo,
    Logo90Deg,
    PositionLogo,
    BeatLogo,
    AlignmentLogo,
    Parallels2Logo,
  },
};
</script>
